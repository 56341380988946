import React, { forwardRef, Fragment } from 'react';
import Skeleton from '@mui/joy/Skeleton';
import AspectRatio from '@mui/joy/AspectRatio';
import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';

import {useState,  useEffect} from 'react';
import axios from 'axios';
import TopMenu from "./components/top_menu";
import SideMenu from "./components/side_menu";
import { ajaxconfig } from "../ajaxconfig";
import { ajax_config_file } from '../ajax_config_file';
import {ToastContainer, toast} from 'react-toastify';
import {upload_file, ImgFile, private_all_asset, private_delete_asset} from "../serverurl";
const block = {display: "block"};
const none = {display: "none"};



const hidden = {
    display: 'none'
};

const visible = {
    visibility: 'block'
};














const CbtImgLibrary = ()=>{

    

    const [file, set_file] = useState("");
    const [block_modal, set_block_modal] = useState(none);
    const [db_img_files, set_db_img_file] = useState([]);
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    const [loading, setLoading] = React.useState(true);


const refresh_img = ()=>{
            // fetch all files 
            axios.get(private_all_asset, ajaxconfig).then((response)=>{
                console.log(response);
                set_db_img_file(response.data.img_files);
            }).catch((error)=>{
    
                console.log(error);
            });
    
}    
const upload_img = (e)=>{
    e.preventDefault();
    set_button_active(hidden);
    set_button_effect(visible);
        const formData = new FormData();
 
        formData.append('uploaded_file', file);
        
    axios.post(upload_file, formData, ajax_config_file).then((response)=>{

        console.log(response);
        set_block_modal(none);
        set_button_active(visible);
        set_button_effect(hidden);
        refresh_img();
    }).catch((error)=>{
        set_button_active(visible);
        set_button_effect(hidden);
        console.log(error);
    });
}


  const close_modal = ()=>{
    set_block_modal(none);
   }

   const open_modal = ()=>{
    set_block_modal(block);
   }


    useEffect(()=>{

        // fetch all files 
        axios.get(private_all_asset, ajaxconfig).then((response)=>{
            console.log(response);
            // setLoading(false);
            set_db_img_file(response.data.img_files);
        }).catch((error)=>{

            console.log(error);
        });
    }, []);
    return(
      <>
        
        <d-section className="main-container">
    <side  className="side-close" id="side-menu">
       
       <SideMenu />

    </side>
         
        <main  className="main-close" id="main">
        <div className="header-container" style={{width: '100%'}} >
             <TopMenu />
        </div>
           

        
<div className="custom-card" style={{marginTop: '70px'}}>








{/* start */}

<div className="card w-100 mx-auto" style={{position: 'static'}}>
        <div className='card-header'>
        <button className="btn btn-info" onClick={open_modal}>New</button>
        </div>
        <div className="card-body">
            <div className="" style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', rowGap: '7px', columnGap: '7px'}}>
                {
                    db_img_files.map((data)=>(
                        <ImgLoop data={data}  refresh_img={refresh_img} />
                    ))
                }
            </div>
        </div>
{/* end card */}
</div>


{/* end custom card */}
</div>


<div id="modal" className="modal"  style={block_modal}>
    <div className="modal-content">
        <div className="card">
            <div className="card-header">
                 <button className="btn btn-danger" onClick={close_modal}>x</button>
            </div>
            <div className="card-body">
            <form onSubmit={upload_img} >
                <div className="form-group">
                    <label>
                        <b>Img</b>
                    <input type="file" accept="image/*" onChange={(e)=>set_file(e.target.files[0])} />
                    </label>
                </div>
                <div className='form-group'>
                <button className="btn btn-danger" style={button_effect} disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                <button className="btn btn-danger" style={button_active}>Upload</button>
                </div>
            </form>
            </div>

        </div>
    </div>
</div>
<ToastContainer />
      </main>
</d-section>    
        </>
    )


}


const ImgLoop = forwardRef(({data, refresh_img})=>{

    const [zoom_diagram_effect, set_zoom_diagram_effect] = useState(none);
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);


    const zoom_diagram = ()=>{
        set_zoom_diagram_effect(block);
    }
    const close_zoom_diagram_effect = ()=>{
        set_zoom_diagram_effect(none);

    }

    const delete_images = ()=>{
        const datas = {
            file_name : data.file_name
        }
        set_button_active(hidden);
        set_button_effect(visible);
        axios.post(private_delete_asset, datas, ajaxconfig).then((response)=>{
            console.log(response);
            set_button_active(visible);
            set_button_effect(hidden);
            set_zoom_diagram_effect(none);
            refresh_img();
            toast(response.data.message);
        }).catch((error)=>{
            set_button_active(visible);
            set_button_effect(hidden);
            console.log(error);
            toast(error);
        });
    }
    return(<>
                   <div key={data.id} className="card static" style={{width:'25%'}}>
                         <img onClick={zoom_diagram} className="mx-auto" style={{width:'100%', marginLeft: '3%'}} src={ImgFile+data.file_name} alt={data.file_name} />
                        
                        </div>    

                             {/* zoom image modal */}
      <div id="modal" className="modal"  style={zoom_diagram_effect}>

<div className="modal-content">
{/* contents end */}
<div className="card w-100">
<div className="card-body">
<img className="" style={{width:'100%'}}   src={ImgFile+data.file_name} alt="" />

</div>
<div className="card-footer">
  <div className="form-group d-flex flex-wrap"  >
      <button className="btn btn-danger" style={button_effect} disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
      <button className="btn btn-danger" style={button_active} onClick={delete_images}>Delete</button>
      <button className="btn btn-info" style={{marginLeft: '7px'}} onClick={close_zoom_diagram_effect}>Cancel</button>
  </div>
</div>
</div>
{/* contents */}
</div>

</div>                
{/* modal end */}
    </>)
});

export {CbtImgLibrary};
