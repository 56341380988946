import React, { forwardRef } from "react";
import {useState, useEffect, useRef, useReducer, useContext, createContext} from "react";
import axios from "axios";
import TopMenu from "./components/top_menu";
import SideMenu from "./components/side_menu";
import DashboardWidget from './components/dashboard_widget';
import { ajaxconfig } from "../ajaxconfig";
import { useNavigate  } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import cookie from "universal-cookie";


// custom css


import { custom_side_open, custom_side_close} from "../customcss";

// custom css end
import QRCode from "react-qr-code";
import {VerifyPublicCbtPassCode, VerifyPublicGetCbtPassCode, FinalizedGrade,
    PublicMassCbtQuestionGet, CbtPermitVerification, TakeCbtPermitVerification, CbtTestData, StudentAnswerSubmit, StudentGradeSubmit} from "../serverurl";
import { QueryErrorResetBoundary } from "@tanstack/react-query";
// active button
const hidden = {
    display: 'none'
};

const visible = {
    visibility: 'block'
};

const block = {display: "block"};
const none = {display: "none"};

const CbtPassCode = ()=>{
    const cookies = new cookie();
    //url parameter
    const url_param = new URLSearchParams(window.location.search);

    const get_cbt_passcode_url = url_param.get("cbt_pass_code");

    const [cbtpasscode, setcbtpasscode] = useState(get_cbt_passcode_url);
    const [test_id, set_test_id] = useState(cookies.get('email'));
    const navigate = useNavigate();
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    // verify the cbt pass code 

   


    const verify_cbt_data_pass_code = (e)=>{
     
        e.preventDefault();
        set_button_active(hidden);
        set_button_effect(visible);
        const cbt_verify_data = {
            cbt_data_id : cbtpasscode
        }

        axios.post(VerifyPublicCbtPassCode, cbt_verify_data, ajaxconfig).then((response)=>{
           
            set_button_active(visible);
            set_button_effect(hidden);
            toast(response.data.message);
            if(response.data.serverstatus === 200){
                navigate("/cbt_user/verification?cbt_pass_code="+cbtpasscode+'&test_id='+test_id);
            }

        }).catch((error)=>{

            toast(error);
            set_button_active(visible);
            set_button_effect(hidden);
            console.log(error);
        });
    }
 return (

    <>

        <d-section className="main-container">
        <ToastContainer />
    <main className="main-close" id="main" >
        <div className="header-container" style={{width: '100%'}} >
            <TopMenu />
        </div>

        {/* verify cbt pass code start */ }
        <div className="card card-size mx-auto" style={{marginTop: '70px'}}>
            <form className="w-75" onSubmit={verify_cbt_data_pass_code}>
                <div class="form-group mt-2">
                    <h2 class="text-center">CBT PASSCODE </h2>
                </div>
                <div className="form-group mt-2">
                        <input value={cbtpasscode} onChange={(e)=>setcbtpasscode(e.target.value)}  placeholder="Enter CBT PASS CODE" />
                </div>
                <div className="form-group mt-2">
                        <input value={test_id} onChange={(e)=>set_test_id(e.target.value)}  placeholder="Enter CBT TEST ID" />
                </div>
                <div className="form-group mt-2 mb-5">
                    <button className="btn btn-danger w-100" style={button_active}>
                       Verify
                    </button>
                    <button className="btn btn-danger w-100" style={button_effect} disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                       
                </div>   
            </form>
        </div>
        {/* verify cbt pass code end */}

        <div className="custom-card mt-2">
            <table className="table">
                <tr>
                    <th>#</th>
                    <th>Data</th>
                    <th>Action</th>
                </tr>
                <tbody>
                    
                </tbody>

            </table>
        </div>
      </main>
</d-section>    
        </>
 )

}

const CbtPassCodeVerification = () => {


    const [cbt_pass_code_verify_info, set_cbt_pass_code_verify_info] = useState("");
    const [cbt_data, set_cbt_data] = useState([]);
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    const cookies = new cookie();
    
    const [cbt_pass_code_info, set_cbt_pass_code_info] = useState(cookies.get("name"));

    //url parameter
    const url_param = new URLSearchParams(window.location.search);

    const cbt_passcode = url_param.get("cbt_pass_code");
    const test_id = url_param.get("test_id");
    const [cbt_test_id_verify, set_cbt_test_id_verify] = useState(test_id);
    const navigate = new useNavigate();
    const cbtbarcode = "https://cbtdraft.com/cbt_user/cbt_pass_code?cbt_pass_code=" + cbt_passcode;

    const cbt_permit_code_verification = (e)=>{

        e.preventDefault();

        // verifies cbt data id
        const data = {
            cbt_permit_pass_code: cbt_test_id_verify
        }
        toast(CbtPermitVerification);
        axios.post(CbtPermitVerification, data, ajaxconfig).then((response)=>{
            toast(response.data.message);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
        
        // navigate("/cbt/cbt_question?cbt_pass_code="+cbt_passcode+"&cbt_permit_pass_code="+cbt_pass_code_verify);
        
    }


    
        const cbt_permit_code_manual_verification = (e)=>{
    
            e.preventDefault();
            set_button_active(hidden);
            set_button_effect(visible);
    
            // verifies cbt data id
            const data = {
                cbt_test_id: cbt_test_id_verify,
                cbt_test_id_info: cbt_pass_code_info,
                cbt_pass_code: cbt_passcode
            }
            axios.post(TakeCbtPermitVerification, data, ajaxconfig).then((response)=>{
                set_button_active(visible);
                set_button_effect(hidden);
                toast(response.data.message);

                if(response.data.serverstatus === 200){
                    navigate("/cbt_user/cbt_question?cbt_pass_code="+cbt_passcode+"&cbt_test_id="+cbt_test_id_verify);

                }
            }).catch((error)=>{
                set_button_active(visible);
                set_button_effect(hidden);
                toast(error);
                console.log(error);
            });
            
            
        }



        
    useEffect(()=>{

        // verify cbt pass code 
        const url = VerifyPublicGetCbtPassCode + cbt_passcode; 
        axios.get(url, ajaxconfig).then((response)=>{
            set_cbt_data(response.data.data);
            console.log(response);
            toast(response.data.message);
            if(response.data.serverstatus !== 200){
               toast("invalid cbt pass code");
            }

        }).catch((error)=>{
            toast(error);
            console.log(error);
        })

    }, []);
    return (

        <>

            <d-section className="main-container">
        <main>
        <ToastContainer />
            <div className="header-container w-100" >
    
             <TopMenu />
            </div>
     
               {/* verify cbt pass code start */}
               <div className="custom-card d-flex flex-wrap" style={{marginTop: '70px'}}>

            <table className="table w-75 mt-3 mb-3">
                <tr>
                    <th>
                        CBTDRAFT PASS CODE:
                    </th>
                    <td>{cbt_passcode}</td>
                </tr>
                <tr>
                    <th>
                        Description:
                    </th>
                    <td>{cbt_data.cbt_name}</td>
                </tr>
                <tr>
                    <th>
                        Status:
                    </th>
                    <td>{cbt_data.cbt_status}</td>
                </tr>
                <tr>
                    <th>
                        Total question:
                    </th>
                    <td>{cbt_data.question_count}</td>
                </tr>
                <tr>
                    <th>
                        Test Id:
                    </th>
                    <td>{test_id}</td>
                </tr>
            </table>

            <table className=" table w-25 mt-3 mb-3">
            <QRCode
            size={256}
            style={{ height: "auto", maxWidth: "100%", width: "70%" }}
            value={cbtbarcode}
            viewBox={`0 0 256 256`}
            />
            </table>
            </div>
            {/* verify cbt pass code start */}

    

    
                {/* verify cbt pass code start */}
                <div className="card card-size mx-auto mt-2" style={{padding:'1%'}}>
                <form className="" onSubmit={cbt_permit_code_manual_verification} >
                    
                    <div className="form-group mt-2">
                            <input value={cbt_test_id_verify} onChange={(e)=>set_cbt_test_id_verify(e.target.value)}  placeholder="Enter CBT PERMIT PASS CODE" />
                    </div>
                    <div className="form-group mt-2">
                            <input value={cbt_pass_code_info} onChange={(e)=>set_cbt_pass_code_info(e.target.value)}  placeholder="Enter CBT information" />
                    </div>
                    <div className="form-group mt-2 mb-5">
                        <button style={button_active} className="btn btn-danger w-100">
                           Take CBTDRAFT
                        </button>
                        <button style={button_effect} className="btn btn-danger w-100" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                    </div>   
                </form>
            </div>
            {/* verify cbt pass code end */}

          </main>
    </d-section>    
            </>
     )
    
}

const CbtQuestionMap = (props)=>{
    const submitAnswer = (e)=>{
        e.preventDefault();
    } 
    <form key={props.data.id} className="w-100 mx-auto mt-3" onSubmit={submitAnswer} >
        <input  name="cbt_question_id" type="hidden" value={props.data.cbt_question_id} />
        <div className="card  card-size" style={{position: 'static'}}>
            <div className="card-header">
                        <p className="text-justify">{props.data.cbt_question}</p>
            </div>
            <div className="card-body">
                 {props.data.cbt_answer.map((option_data)=>(

      
        <div className="form-group mt-2 mb-1 d-flex align-items-center">
                        <button type="submit" name="question" className="w-100 btn btn-danger">{option_data.options}</button>
                </div>   
                                ))}
            </div>
        </div>            
                </form>

} 




const CbtQuestion = ()=>{

    // cbt questions  state

    const [db_cbt_question, set_db_cbt_question] = useState([]);
    const [cbt_data, set_cbt_data] = useState([]);
    const [question_count, set_question_count] = useState("");

    const url_param = new URLSearchParams(window.location.search);
    const cbt_pass_code = url_param.get("cbt_pass_code");
    const cbt_permit_code = url_param.get("cbt_permit_pass_code");
    const cbt_test_id = url_param.get("cbt_test_id");
    const navigate = new useNavigate();
    const cbtbarcode = "https://cbtdraft.com/cbt_user/cbt_pass_code?cbt_pass_code="+cbt_pass_code;

    const Ref = useRef(null);
    const [timer, setTimer] = useState("00:00:00");
    
    const [cbt_question_id, set_cbt_question_id] = useState("");
    const [cbt_option_id, set_cbt_option_id] = useState("");
   

    const [day, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const deadline = "December, 31, 2024";



    const [block_modal, set_block_modal] = useState(none);
    const close_modal = ()=>{
      set_block_modal(none);
     }
  
     const open_modal = ()=>{
      set_block_modal(block);
     }
  

    if(!cbt_pass_code){
        navigate("/cbt_user/cbt_pass_code/code");

    }    

    const finalizedCbt = ()=>{


        const data = {
            cbt_pass_code : cbt_pass_code,
            cbt_test_id : cbt_test_id
        }
        axios.post(StudentGradeSubmit, data, ajaxconfig).then((response)=>{
            toast(response.data.message);
            console.log(response);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        })
    }

    const gradeStudent = ()=>{
        const data = {
            cbt_pass_code : cbt_pass_code,
            cbt_test_id : cbt_test_id
        }

        

        axios.post(FinalizedGrade, data, ajaxconfig).then((response)=>{
            toast(response.data.message);
            console.log(response);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });

    }
    const getTimerRemaining = (e)=>{
        
        const total = Date.parse(e) - Date.parse(new Date());
        const seconds = Math.floor((total/1000) % 60);
        const minutes = Math.floor((total/1000) % 60);
        const hours = Math.floor((total/ 1000 / 60 /60) % 24);

        return {

            total, 
            hours,
            seconds,
            minutes
        };
    }

    const startTimer = (e) =>{
    
        let { total, hours, seconds, minutes } = getTimerRemaining(e);

        if(total >= 0){

            setTimer((hours > 9 ? hours: "0" + hours) + ":" + (minutes > 9 ? minutes :  "0" + minutes) + ":" + (seconds > 9 ? seconds : "0" + seconds));
        }
    }

    const clearTimer = (e)=>{
        // from db
        setTimer("01:59:10");

        if(Ref.current) clearInterval(Ref.current);

        const id = setInterval(()=>{
            startTimer(e);
        }, 1000);
        Ref.current = id;
    }

    const getDeadline = ()=>{

        // additional time
        let deadline = new Date();
        deadline.setSeconds(deadline.getSeconds() + 100);
         return deadline;
    }

    const GetQuestion = PublicMassCbtQuestionGet + cbt_pass_code+"/"+cbt_permit_code;

    const refresh = ()=>{
        axios.get(GetQuestion, ajaxconfig).then((response)=>{
            toast(response.data.message);
            console.log(response);
            if(response.data.data){
                set_db_cbt_question(response.data.data);
                set_question_count(response.data.question_count);

            }

        }).catch((error)=>{
            toast(error);
            console.log(error);
        });

    }

    const getTime = ()=>{

        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time/ (1000 *60 *60*24)));
        setHours(Math.floor((time/ (1000 *60 *60)) % 24));
        setMinutes(Math.floor((time /1000/60) % 60));
        setSeconds(Math.floor((time /1000) % 60));
        
       
    }



    useEffect(()=>{
            console.log(GetQuestion);
            clearTimer(getDeadline());
        // fetching question 
        const url = VerifyPublicGetCbtPassCode + cbt_pass_code; 

        axios.get(url, ajaxconfig).then((response)=>{
            toast(response.data.message);
            if(response.data.data){
                set_cbt_data(response.data.data);

            }
            console.log(response); });

        
        axios.get(GetQuestion, ajaxconfig).then((response)=>{
            toast(response.data.message);
            console.log(response);
            if(response.data.data){
                set_db_cbt_question(response.data.data);
                set_question_count(response.data.question_count);

            }

        }).catch((error)=>{
            toast(error);
            console.log(error);
        });

        // timer
        const interval = setInterval(()=>getTime(deadline), 1000);
        return ()=>clearInterval(interval);
     
    },[cbt_pass_code, cbt_permit_code]);
    
   
    

    
    return (

         <>
       
            <d-section className="main-container">
        
        <main>
        <ToastContainer />
            <div className="header-container w-100" >
    
                 <TopMenu />
            </div>

                    {/* verify cbt pass code start */}
                    <div className="custom-card d-flex flex-wrap" style={{marginTop: '70px'}}>
                    <div className="w-25 mt-3 mb-3">
                        <QRCode
    size={256}
    style={{ height: "auto", maxWidth: "100%", width: "70%" }}
    value={cbtbarcode}
    viewBox={`0 0 256 256`}
    />
                        </div>
                        <table className="table w-75 mt-3 mb-3">
                        <tr>
                                <th>
                                    Cbt Test Id:
                                </th>
                                <td>{cbt_test_id}</td>
                            </tr>
                            <tr>
                                <th>
                                    CBT PassCode:
                                </th>
                                <td>{cbt_pass_code}</td>
                            </tr>
                            <tr>
                                <th>
                                    Question count: 
                                </th>
                                <td>{question_count}</td>
                            </tr>
                            <tr>
                                <th>
                                    Description:
                                </th>
                                <td>{cbt_data.cbt_name}</td>
                            </tr>
                            <tr>
                                <th>
                                    Duration:
                                </th>
                                <td>{cbt_data.cbt_duration}</td>
                            </tr>
                        </table>

                        
                    </div>
                    {/* verify cbt pass code start */}

            {/* verify cbt pass code start */}
            <div className="custom-card mt-3">
                {

db_cbt_question.map((data)=>(
    

                  <Obj cbt_pass_code={cbt_pass_code} cbt_test_id={cbt_test_id} qdata={data} cbt_question_id={data.cbt_question_id} user_option={data.user_option} refresh={refresh}  /> 

            ))
                }
            </div>
            {/* verify cbt pass code end */}



    {/* verify cbt pass code start */}
<div className="custom-card" style={{position: 'fixed', bottom: '0'}}>
               <div className="card mx-auto w-75">
                    <div className="card-header d-flex justify-contents-space-between">
                        <button style={{width: '40%'}} onClick={open_modal} className="btn btn-danger mx-auto">Submit</button>

                        <h5 style={{color:'red'}}>{day+ " Days "+hours+" Hours "+ minutes+" Minutes "+ seconds +" Secs"}</h5>

                    </div>
            </div>
</div>
            {/* verify cbt pass code end */}

   
<div id="modal" className="modal"  style={block_modal}>
    <div className="modal-content">
        <div className="card">
            <div className="card-body">
                <p className="text-justify" >Stage 1</p>
                <p style={{color: 'red'}} className="text-justify" >your option/answer can still be review</p>
                <div className="d-flex">
                <button className="btn btn-info" onClick={finalizedCbt}>Submit</button>
                <button className="btn btn-danger" style={{marginLeft: '7px'}} onClick={close_modal}>Cancel</button>

                </div>
            </div>
            <div className="card-body mt-2">
                <p className="text-justify" >Stage 2</p>
                <p style={{color: 'red'}} className="text-justify" >Final submission: no review</p>
                <div className="d-flex">
                <button className="btn btn-info" onClick={ gradeStudent}>Submit</button>

                </div>
            </div>
            <div className="card-footer">
          <div>
        <h5 style={{color:'red'}}>{day+ " Days "+hours+" Hours "+ minutes+" Minutes "+ seconds +" Secs"}</h5>
    </div>
            </div>
           
        </div>
    </div>
</div>
          </main>
          
    </d-section>    

            </>
     )
};



const update_option = (props)=>{

    return(<>



        <form  className="w-100 mx-auto mt-3 mb-5" >
                            
        
                            <div className="card w-75 mx-auto" style={{position: 'static'}}>
                                <div className="card-header">
                                            <p className="text-justify">test</p>
                                           
                                </div>
                                <div className="card-body">
                                     
                    
                          
                            <div className="form-group mt-2 mb-1">
                                            <button  type="submit" name="question" className="w-100 btn btn-danger">
                                         <h6>       option 
                                     
                                        </h6>
                                            </button>
                                            <p className="text-justify mb-3">{props.click_option}</p>
                                           
                                    </div>   
                                                    
                                </div>
                              
                            </div>            
                                    </form>
         
        
            </>);
}








const CountDown = ()=>{

    const [eventName, setEventName] = useState("");
    const [eventDate, setEventDate] = useState("");
    const [countdownStarted, setCountdownStarted] =useState(false);
    const [timeRemaining, setTimeRemaining] = useState(0);
    
    useEffect(()=>{

        if(countdownStarted && eventDate){

            const countdownInterval = setInterval(()=>{

                const currentTime = new Date().getTime();
                const eventTime = new Date(eventDate).getTime();

                let remainingTime = eventTime - currentTime;

                if(remainingTime <= 0){

                    remainingTime = 0;
                    clearInterval(countdownInterval);
                    alert("time exausted");
                }

                return ()=> clearInterval(remainingTime);
            });
        }
        // https://dev.to/yuridevat/how-to-create-a-timer-with-react-7b9
        if(countdownStarted){

            document.title = eventName;
        }
    }, [countdownStarted, eventDate, timeRemaining, eventName]);
    return(<div className="countdown-timer-container">

    </div>);

}



const Timer = ()=>{

    const [day, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    const deadline = "December, 31, 2024";

    const getTime = ()=>{

        const time = Date.parse(deadline) - Date.now();

        setDays(Math.floor(time/ (1000 *60 *60*24)));
        setHours(Math.floor((time/ (1000 *60 *60)) % 24));
        setMinutes(Math.floor((time /1000/60) % 60));
        setSeconds(Math.floor((time /1000) % 60));
        
       
    }
    getTime();

    // useEffect(()=>{

        
    // });

    return (<div>
        <h5>{day+ " : Days "+hours+" : Hours"+ minutes+" : Minutes "+ seconds +" : Secs"}</h5>
    </div>)
}





const Obj = forwardRef(({cbt_pass_code, cbt_test_id, qdata, cbt_question_id, user_option, refresh})=>{


    
    const [cbt_option_id, set_cbt_option_id] = useState("");
    const [click_state, set_click_state] = useState(false);
    const [click_option, set_click_option] = useState("");
    // const [user_option, set_user_option] = useState("");

    // if(props.user_option){
    //     set_user_option(props.user_option.cbt_option_id);
    // }
    

    const submitAnswer = (e)=>{
        e.preventDefault();
      

            set_click_state(true);
            set_click_option(cbt_option_id);
               

        // send the question id and the answer id to db
       
        const data = {
            cbt_question_id: cbt_question_id,
            cbt_option_id: cbt_option_id,
            cbt_pass_code : cbt_pass_code,
            cbt_test_id: cbt_test_id

        }
        axios.post(StudentAnswerSubmit, data, ajaxconfig).then((response)=>{
            toast(response.data.message);
            refresh();
            console.log(response);
        }).catch((error)=>{

            toast(error);
            console.log(error);
        })

    } 



    return(<>



<form key={qdata.id} className="w-100 mx-auto mt-3 mb-5" onSubmit={submitAnswer}>
                    

                    <div className="card w-100 mx-auto" style={{position: 'static'}}>
                        <div className="card-header">
                                    <p className="text-justify">{qdata.cbt_question}</p>
                                   
                        </div>
                        <div className="card-body">
                             {qdata.cbt_answer.map((option_data)=>(
            
                  
                    <div className="form-group mt-2 mb-1">
                                    <button type="submit"  name="question"  className="w-100 btn btn-danger" onClick={()=>set_cbt_option_id(option_data.answer_id)} >
                                 <h6>       option
                                {user_option ?
                                option_data.answer_id === user_option.cbt_option_id ? <img style={{width: '20px'}} src="/asset/user_option.png" /> : ''
                                
                                 : ''}

{/* <input type="radio" onClick={()=>{set_cbt_option_id(option_data.answer_id); set_cbt_question_id(props.cbt_question_id)}} name="option" /> */}
                                {/* {option_data.answer_id == props.user_option.cbt_option_id ? 'user select' : 'h'} */}
                                </h6>
                                    </button>


                                    <p className="text-justify mb-3">{option_data.options}</p>
                                   
                            </div>   
                                            ))}
                        </div>
                      
                    </div>            
                            </form>
 

    </>);
});


const Essay = (props)=>{

    return (<>
    
    <div className="custom-card mt-2" style={{marginBottom: '10%'}}>
                <form className="w-100 mx-auto" >
                    <div class="form-group mt-2">
                        <p class="text-justify">The equipment used to do a particular job at home is called</p>
                    </div>
                    <div className="form-group mt-2 mb-2 d-flex align-items-center">
                      <textarea placeholder="Start typing..." rows="6" />
                        
                    </div>                    
                       
                    
                </form>
            </div>

    </>)
}



export {CbtPassCode, CbtPassCodeVerification, CbtQuestion}