import { Header } from "./header";
import { Footer } from "./footer";
import { Helmet } from 'react-helmet-async';




const AboutUs = ()=>{

    return(<>

<Helmet>
        <meta id="description" name="discription" content="cbtdraft about us" />
        <title id="title">cbtdraft.com | about us</title>
      </Helmet>

    <Header />
   <div className="container default-margin-top">
       <div className="card mt-5 static">

            <div className="card-body">
            <h3 className="text-center">About US</h3>

            <p className="text-justify">
                CBTDRAFT.COM is free automated computer based testing software which helps instructors to evaluate learners, cbtdraft also has cbt and lms-learning management features. 
            </p>
            
            </div>
        </div>


       
   </div>
    <div className="footer card">
<Footer />
</div>
    </>)
}


export {
    AboutUs
}