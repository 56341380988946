// admin url
// const BasedUrl = "http://localhost:8000/api/";
// const FileServer = "http://localhost:8000/api/";
// const ServerImage = "http://localhost:8000/";
// const ServerBased = "http://localhost:8000/";



const BasedUrl = "https://server.cbtdraft.com/api/";
const FileServer = "https://server.cbtdraft.com/api/";
const ServerImage = "https://server.cbtdraft.com/";
const ServerBased = "https://server.cbtdraft.com/";

const AdminCreateUnit = BasedUrl+"admin/admin_create_unit";
const AdminGetUnit = BasedUrl+"admin/admin_get_unit";


// unit url


// staff urls


// authetication


const LoginUrl = BasedUrl + "login";
const SignUpUrl = BasedUrl + "sign_up";
const gmailLogin = BasedUrl + "gmail_login";
const profileData = BasedUrl + "admin/profile_data";
const updateProfile = BasedUrl + "admin/update_profile";


// student urls

const VerifyPublicCbtPassCode = BasedUrl+"cbt_pass_code/cbt_pass_code_public_verify";
const VerifyPublicGetCbtPassCode = BasedUrl+"cbt_pass_code/cbt_pass_code_get_public_verify/";
const PublicMassCbtQuestionGet = BasedUrl + "question/public_mass_cbt_question_get/"; 
// cbt_data_id
const CbtDataIdCreateCbt = BasedUrl+"cbt_pass_code/create_cbt_pass_code";
const CbtDataIdGetCbt = BasedUrl+"cbt_pass_code/get_cbt_pass_code";
const CbtDataIdPrivateCbtDataVerify = BasedUrl+"cbt_pass_code/cbt_pass_code_private_verify/cbt_pass_code=";
const CbtPermitVerification = BasedUrl + "cbt_pass_code/cbt_permit_verification";
const TakeCbtPermitVerification = BasedUrl + "cbt_pass_code/take_cbt_permit_verification";
const CbtTestData = BasedUrl + "cbt_pass_code/permit_verification_data";
const Deletecbtdata = BasedUrl + "cbt_pass_code/delete_cbtdata";
const UpdateCbtDataId = BasedUrl + "cbt_pass_code/update_cbtdata_id";
const Cbt_Csv = BasedUrl + "cbt_pass_code/cbt_csv/";

// cbt question and answers

const CbtQuestionCreate = BasedUrl+"question/create_cbt_question";
const  MassPrivateQuestionGet = BasedUrl+"question/mass_private_question_get/";
const SinglePrivateQuestionGet = BasedUrl+"question/single_private_question_get";
const SinglePrivateAnswerGet = BasedUrl + "question/single_private_single_answer_get/";
const CreateCbtQuestionAnswer = BasedUrl+"question/answer/create_cbt_question_answer";
const update_cbt_question_answer = BasedUrl + "question/answer/update_cbt_question_answer";
const StudentAnswerSubmit = BasedUrl+"question/student_answer_submit";
const StudentGradeSubmit = BasedUrl+"question/student_grade_submit";
const FinalizedGrade = BasedUrl+"question/finalized_grade";
const delete_single_answer = BasedUrl + "question/answer/delete_single_answer";
const delete_cbt_question = BasedUrl + "question/delete_cbt_question";
const update_cbt_question = BasedUrl + "question/update_cbt_question";
const test_result = BasedUrl + "question/test_result/";

// course
const CreateCbtCourse = BasedUrl + "cbt_course/create_cbt_course";
const GetMassPrivateCourses = BasedUrl + "cbt_course/get_mass_private_courses";

// cbt session
const CreateCbtSession = BasedUrl + "cbt_session/create_cbt_session";
const GetPrivateCbtSession = BasedUrl + "cbt_session/get_private_cbt_session";


// cbt draft img
const upload_file = FileServer + "upload_file";
const private_all_asset = FileServer + "private_all_asset";
const ImgFile = ServerImage + "img_asset/";
const add_diagram_to_question = FileServer + "add_diagram_to_question";
const diagram_question = FileServer + "diagram_question/";
const delete_diagram_question = FileServer + "delete_diagram_question";
const update_diagram_question = FileServer + "update_diagram_question";
const private_delete_asset = FileServer + "private_delete_asset";

// blogs
const PostTitles = BasedUrl + "blog_post";
const SinglePost = BasedUrl + "blog/post/";




export {AdminCreateUnit, AdminGetUnit, 
    CbtDataIdGetCbt, CbtDataIdCreateCbt, 
    CbtDataIdPrivateCbtDataVerify, CbtQuestionCreate, 
    MassPrivateQuestionGet, SinglePrivateQuestionGet, 
    CreateCbtQuestionAnswer, SinglePrivateAnswerGet,
    VerifyPublicCbtPassCode,
    VerifyPublicGetCbtPassCode, PublicMassCbtQuestionGet,
     CbtPermitVerification, TakeCbtPermitVerification,
     CreateCbtCourse, GetMassPrivateCourses, 
     CreateCbtSession, GetPrivateCbtSession,
     upload_file, FileServer, private_all_asset,
      ImgFile, LoginUrl, SignUpUrl,
      PostTitles, SinglePost,
      CbtTestData,
      StudentAnswerSubmit,
      StudentGradeSubmit, 
      gmailLogin,
      updateProfile,
      profileData,
      Deletecbtdata,
      UpdateCbtDataId,
      ServerImage,
      add_diagram_to_question,
      diagram_question,
      delete_diagram_question,
      update_cbt_question,
      update_diagram_question,
      delete_single_answer,
      delete_cbt_question,
      update_cbt_question_answer,
      private_delete_asset,
      FinalizedGrade,
      test_result,
      Cbt_Csv,
      ServerBased
}