import React from 'react';
import {useState,  useEffect,forwardRef, useRef, useImperativeHandle} from 'react';
import axios from 'axios';
import TopMenu from "./components/top_menu";
import SideMenu from "./components/side_menu";
import {Cbtdata_list} from "./components/editcbtdata";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ajaxconfig } from "../ajaxconfig";
import { ajax_config_file } from '../ajax_config_file';
import {upload_file, ImgFile, private_all_asset, update_diagram_question, delete_cbt_question,
    update_cbt_question, update_cbt_question_answer,
     delete_diagram_question, delete_single_answer,

    add_diagram_to_question, diagram_question} from "../serverurl";











import { Table, Divider } from "@mui/joy";
import Button from '@mui/joy/Button';
import Modal from '@mui/joy/Modal';
import ModalClose from '@mui/joy/ModalClose';
import Typography from '@mui/joy/Typography';
import Sheet from '@mui/joy/Sheet';



import {CbtDataIdGetCbt, UpdateCbtDataId, CbtDataIdCreateCbt, CbtDataIdPrivateCbtDataVerify, CbtQuestionCreate, Deletecbtdata,
    MassPrivateQuestionGet, GetMassPrivateCourses, SinglePrivateQuestionGet, CreateCbtQuestionAnswer, 
    SinglePrivateAnswerGet, Cbt_Csv} from "../serverurl";

    const hidden = {
        display: 'none'
    };
    const visible = {
        visibility: 'block'
    };
    const block = {display: "block"};
    const none = {display: "none"};
    

const Cbt = ()=>{

    

    const [cbt_name_f, set_cbt_name ] = useState("");
    const [attempt_limit, set_attempt_limit] = useState(1);
    const [default_score, set_default_score] = useState(1);
    const [cbt_duration, set_cbt_duration] = useState("");
    const [max_question, set_max_question] = useState("");
    const [random_question, set_random_question] = useState("no");
    const [cbtdata, setcbtdata] = useState([]);
    const [duration_style, set_duration_style] = useState(hidden);
    const [max_attempt_style, set_max_attempt_style] = useState(hidden);
    const [score_style, set_score_style] = useState(hidden);
    const [cbt_status, set_cbt_status] = useState('draft');
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);
    const [block_modal, set_block_modal] = useState(none);
    const [open, setOpen] = React.useState(false);
    const close_modal = ()=>{
      set_block_modal(none);
     }
  
     const open_modal = ()=>{
      set_block_modal(block);
     }


    useEffect(()=>{

        axios.get(CbtDataIdGetCbt, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
            setcbtdata(response.data.data);
        }).catch((error)=>{

            toast(error);
            console.log(error);
        });



    }, []);

    const refreshpage = ()=>{

        axios.get(CbtDataIdGetCbt, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
            setcbtdata(response.data.data);
        }).catch((error)=>{

            toast(error);
            console.log(error);
        });

    }


    const submit = (e)=>{
        const data = {
            'cbt_name' : cbt_name_f,
            'attempt_limit' : attempt_limit,
            'default_score' : default_score,
            'cbt_duration'  : cbt_duration,
            'max_question'  : max_question,
            'random_question_mode' : random_question,   
            'cbt_status' : cbt_status,
        }
        e.preventDefault();
        // run validation 
        set_button_active(hidden);
        set_button_effect(visible);
        // send request to the server
        axios.post(CbtDataIdCreateCbt, data, ajaxconfig).then((response)=>{
            console.log(response);
            setcbtdata(response.data.data);
            set_button_active(visible);
            set_button_effect(hidden);
            set_cbt_name("");
            toast(response.data.message);
        }).catch((error)=>{
            set_button_active(visible);
            set_button_effect(hidden);
            toast(error);
            console.log(error);
        });

    }
    return(
    <>

        <d-section className="main-container">
    <side className="side-close" id="side-menu">
       
       <SideMenu />

    </side>
   
        <main className="main-close" id="main">
                <div className="header-container" style={{width: '100%'}} >     
                     <TopMenu />
                </div>

<div className="card static" style={{marginTop: '70px'}}>   
<div className="card-header">
    <Button variant="outlined" color="neutral" onClick={() => setOpen(true)}>
        Open modal
      </Button>
</div>       
<form   onSubmit={submit} >


<div className="card-body static w-100 mx-auto" style={{padding: '10px'}}>

                <div className="form-group-2 mt-2">

                    <label>
                    <h6>CBT Name</h6>
                        <input 
                    value={cbt_name_f} onChange={(e)=>set_cbt_name(e.target.value)} placeholder="CBT name" 
                    /> </label>
                </div>

                

            <div className="form-group-2 mt-2" style={max_attempt_style} >

                <label>
                    <h6>Max Attempt</h6>
                    <input input type="number"
                value={attempt_limit} onChange={(e)=>set_attempt_limit(e.target.value)} placeholder="Attempt limit" 
                /> </label>
            </div>

            <div className="form-group-2 mt-2" style={score_style}>

                <label>
                    <h6>Default score</h6>
                    <input input type="number"
                value={default_score} onChange={(e)=>set_default_score(e.target.value)} placeholder="Default score" 
                /> </label>
            </div>
            <div className="form-group-2 mt-2" style={duration_style} >
                <label>
                <h6>Duration in minutes:</h6>
                    <input type="number"
                value={cbt_duration} onChange={(e)=>set_cbt_duration(e.target.value)} placeholder="CBT duration" 
                /> </label>
            </div>

                <div className="form-group-2 mt-1 mb-2" style={button_active}>
                    <button className="btn btn-danger w-100" >Create CBT </button>
                </div>
                <div className="form-group mt-1 mb-1" style={button_effect} >
                           <button className="btn btn-danger w-100" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                </div>

        
</div>

</form>   
</div>




<div className=" mt-2 mb-3" >
<div className="card static w-100 mx-auto">
        <Table aria-label="striped table" >
        <thead>
            <tr>
                <th>CBT</th>
                <th>Date</th>
                <th>Action</th>
            </tr>
        </thead>  
        <tbody>
            {
                cbtdata.map((data)=>(
                    <Cbtdata_list refreshpage={refreshpage} datalist={data} />
                ))
            }
  
        </tbody>      
    </Table>

</div>
</div>


<ToastContainer />


{/* new modal */}
<Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{ maxWidth: 500, borderRadius: 'md', p: 3, boxShadow: 'lg' }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            sx={{ fontWeight: 'lg', mb: 1 }}
          >
            Config
          </Typography>
          <Typography id="modal-desc" textColor="text.tertiary">
          <div className="card-body">
            

          
            <div className="form-group-2 mt-2" >
                <b>Duration:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_duration_style(hidden)} type="radio" className="radio" name="duration_type" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input onClick={()=>set_duration_style(visible)} type="radio" className="radio" name="duration_type" /> 
                    </label>
               </div>

            </div>
            

            <div className="form-group-2 mt-2" >
                <b>Attempt limit:</b>
                <div style={{display: 'flex'}}>
                    <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_max_attempt_style(hidden)} type="radio" className="radio" name="attempt_limit" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input onClick={()=>set_max_attempt_style(visible)} type="radio" className="radio" name="attempt_limit" /> 
                    </label>
                </div>
                
            </div>


            <div className="form-group-2 mt-2" >
                <b>custom score:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> No</b>
                        <input onClick={()=>set_score_style(hidden)} type="radio" className="radio" name="custom_score" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Yes</b>
                        <input onClick={()=>set_score_style(visible)} type="radio" className="radio" name="custom_score" /> 
                    </label>
               </div>

            </div>


            <div className="form-group-2 mt-2" >
                <b>CBT status:</b>
               <div style={{display: 'flex'}}>
                <label style={{display: 'flex'}}>
                        <b> Published</b>
                        <input onClick={()=>set_cbt_status('published')} type="radio" className="radio" name="cbt_status" /> 
                    </label>
                    <label style={{display: 'flex'}}>
                        <b>Draft</b>
                        <input onClick={()=>set_cbt_status('draft')} type="radio" className="radio" name="cbt_status" /> 
                    </label>
               </div>

            </div>
            </div>
          </Typography>
        </Sheet>
      </Modal>

{/* new modal */}






      </main>
</d-section>
    </>
    );

}






const CbtAnswerEdit = ()=>{

    const [cbt_data_validate, set_cbt_data_validate] = useState(false);
    const [answer, set_answer] = useState([]);

    // db question 
    const [db_questions, set_db_questions] = useState("");
    const [current_option, set_current_option] = useState("");
    const [current_option_id, set_current_option_id] = useState("");
    const [cbt_question_id, set_cbt_question_id] = useState("");

    // create answer
    const [option_status, set_option_status] = useState("options");
    const [custom_mark_status, set_custom_mark_status] = useState("false");
    const [custom_mark, set_custom_mark] = useState("");
    // get the url parameter
    const url_param = new URLSearchParams(window.location.search);
    const cbt_data_passcode = url_param.get("cbt_data_id"); 
    const cbt_question_passcode = url_param.get("cbt_question_id"); 
    const cbt_answer_route = url_param.get("cbt_answer_id");
    // validate cbt data id

    useEffect(() =>{

        // validating cbt data id
        const url = CbtDataIdPrivateCbtDataVerify+cbt_data_passcode;
        axios.get(url, ajaxconfig).then((response)=>{
            console.log(response);
            const cbt_data_id = response.data.data.cbt_data_id;

            toast(response.data.message);

        
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });


        // fetching already created single question all answers
        const single_question_url = SinglePrivateQuestionGet+"/"+cbt_data_passcode+"/"+cbt_question_passcode;

        axios.get(single_question_url, ajaxconfig).then((response)=>{

            const single_question_db_fetch = response.data.cbt_question;
            if(!single_question_db_fetch){
                
                toast(response.data.message);
            }
            console.log(response);


                set_cbt_question_id(response.data.cbt_question_id);
                set_db_questions(single_question_db_fetch);
                set_answer(response.data.cbt_answer);
               
        }).catch((error)=>{


            console.log(error);
        });

        // edit answer reequest
        const editurl = SinglePrivateAnswerGet+cbt_data_passcode+"/"+cbt_question_passcode+"/"+cbt_answer_route;
        axios.get(editurl, ajaxconfig).then((response)=>{
            console.log(response);
            set_current_option(response.data.data.options);
            set_current_option_id(response.data.data.answer_id);
            set_option_status(response.data.data.option_status);
            set_custom_mark_status(response.data.data.custom_mark_status); 
            set_custom_mark(response.data.data.custom_mark);
        }).catch((error)=>{
            console.log(error);
            console.log("am not working");
            toast(error);

        });
    }, []
);


    // instant updating 
    
    // updating previous answer with the id 
    const answersubmit = (event)=>{
        event.preventDefault();

        // data
        const submitanswer = {
            option : current_option,
            option_status: option_status, 
            custom_mark_status : custom_mark_status, 
            custom_mark : custom_mark,
            cbt_question_id: cbt_question_passcode,
            cbt_data_id : cbt_data_passcode,
            answer_id: current_option_id
        }

        console.log(submitanswer);
        axios.post(CreateCbtQuestionAnswer, submitanswer, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
    } 


return(
    <>
  
        <d-section className="main-container">
    <side className="side-close" id="side-menu">
       
       <SideMenu />

    </side>
   
        <main className="main-close" id="main">
                <div className="header-container" style={{width: '100%'}} >
                    <TopMenu />
                </div>

<div className="custom-card mt-2" >
<div className="card w-100 static" style={{marginTop: '70px'}}>
            <form onSubmit={answersubmit} >
                <div className="form-group">
                    <h4 className="text-center">Question</h4>
                    <p className='text-justify'>{db_questions}</p>
                </div>
                <h4 className="text-center">Edit option</h4>
                <div className="form-group">
                    <label>
                        <h6>Option</h6>
                        <textarea rows="10" value={current_option} onChange={(e)=>set_current_option(e.target.value)} />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <h6>Option Status</h6>
                        <select value={option_status} onChange={(e)=>set_option_status(e.target.value)} >
                            <option>options</option>
                            <option>correct</option>
                        </select>
                    </label>
                </div>
                <div className="form-group mb-3">
                    <button className="btn btn-danger w-100">Edit option</button>
                </div>
            </form>    
</div>            
</div>

<div className="custom-card mt-2" >
<div className="card w-100 static">
    <div className="card-header">
        <h4 className="text-center"><b>Options</b></h4>
    </div>
    <div className="card-body">
        <table className="table">
                <thead>
                    <tr>
                    <th>#</th>
                    <th>CBT Option</th>
                    <th>Option Status</th>
                    <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        answer.map((answer)=>(
                        <tr key={answer.id}>
                            <td>{answer.id}</td>
                            <td>{answer.options}</td>
                            <td>{answer.option_status}</td>
                            <td className="d-flex"><a href={"/cbt_creator/cbt_question_edit_answer?cbt_data_id="+cbt_data_passcode + "&cbt_question_id=" + cbt_question_id + "&cbt_answer_id="+answer.answer_id} ><button className="btn btn-danger">Edit Option</button></a><button  className="btn btn-danger mr-2">Delete</button></td>
                        </tr>
                        ))
                    }
                </tbody>
            </table>

    </div>
</div>
</div>
<ToastContainer />
      </main>
</d-section>
    </>
    );
}

const QuestionImageLoop = forwardRef(({data, cbt_question_id,diagram_refresh,set_img_modal })=>{

    // question images

    const [select_question_image_description, set_select_question_image_description] = useState("");
    // set_select_question_image(props.data.file_name);

    const addCbtQuestionImage = ()=>{

        // alert(props.data.file_name);
        const datas = {
            cbt_question_id : cbt_question_id,
            diagram : data.file_name,
            description : select_question_image_description
        }
        axios.post(add_diagram_to_question, datas, ajaxconfig).then((response)=>{
            console.log(response);
            set_img_modal(none);
            diagram_refresh();
            toast(response.data.message);

        }).catch((error)=>{
            diagram_refresh();
            console.log(error);
            toast(error);
        });
    }
return(<>
<div key={data.id} className="card" style={{width:'25%'}}>
                     <img className="mx-auto" style={{width:'100%', marginLeft: '3%'}} src={ImgFile+data.file_name} alt={data.file_name} />
                    <input placeholder="description..." value={select_question_image_description} onChange={(e)=>set_select_question_image_description(e.target.value)} />
                    <button onClick={addCbtQuestionImage} className="btn btn-danger">Import</button>
                    </div>
</>)
});

const DiagramLoop = forwardRef(({diagram, diagram_refresh})=>{

        // active button
        const hidden = {
            display: 'none'
        };
    
        const visible = {
            visibility: 'block'
        };
    const [zoom_diagram_effect, set_zoom_diagram_effect] = useState(none);
    const [diagram_description, set_diagram_desscription] = useState(diagram.description);
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    const zoom_diagram = ()=>{
        set_zoom_diagram_effect(block);
    }
    const close_zoom_diagram_effect = ()=>{
        set_zoom_diagram_effect(none);

    }

    const delete_diagram = ()=>{
        const data = {
            id : diagram.id,
            cbt_question_id: diagram.cbt_question_id,
            file_name: diagram.diagram
        } 
        // alert("ready to delete");
        set_button_active(hidden);
        set_button_effect(visible);
        axios.post(delete_diagram_question, data, ajaxconfig).then((response)=>{
            console.log(response);
            set_button_active(visible);
            set_button_effect(hidden);
            set_zoom_diagram_effect(none);
            diagram_refresh();
        }).catch((error)=>{
            set_button_active(visible);
            set_button_effect(hidden);
            toast(error);
            console.log(error);
        });
    }
    
    const up_diagram_description = ()=>{
        set_button_active(hidden);
        set_button_effect(visible);
        // alert("ready to update");
        const data = {
            id : diagram.id,
            cbt_question_id: diagram.cbt_question_id,
            file_name: diagram.diagram,
            description : diagram_description
        } 
        // alert("ready to delete");

        axios.post(update_diagram_question, data, ajaxconfig).then((response)=>{
            console.log(response);
            toast(response.data.message);
            set_button_active(visible);
            set_button_effect(hidden);
                        set_zoom_diagram_effect(none);
        }).catch((error)=>{
            toast(error);
            set_button_active(visible);
            set_button_effect(hidden);
            console.log(error);
        });
    }
    return(<>
    
    <img className="" style={{width:'25%'}} onClick={zoom_diagram} title={diagram_description} src={ImgFile+diagram.diagram} alt="" />
     
     {/* zoom image modal */}
      <div id="modal" className="modal"  style={zoom_diagram_effect}>

      <div className="modal-content">
{/* contents end */}
<div className="card w-100">
    <div className="card-header">
    <buttton onClick={close_zoom_diagram_effect} className="btn btn-danger">close</buttton>
    </div>
    <div className="card-body">
    <img className="" style={{width:'100%'}}  title={diagram_description} src={ImgFile+diagram.diagram} alt="" />
                            <input placeholder="description..." value={diagram_description} onChange={(e)=>set_diagram_desscription(e.target.value)}  />

    </div>
    <div className="card-footer">
        <div className="form-group d-flex flex-wrap"  >
            <button className="btn btn-info" onClick={up_diagram_description}>Save</button>
            <button className="btn btn-danger" style={{marginLeft: '7px'}} onClick={delete_diagram}>Delete</button>
        </div>
    </div>
</div>
{/* contents */}
      </div>

      </div>                
    </>)
}
);

const OptionLoop = forwardRef(({option_refresh,answer, cbt_data_passcode, cbt_question_id })=>{
    const [current_option, set_current_option] = useState(answer.options);
    const [current_option_id, set_current_option_id] = useState("");
    const [option_status, set_option_status] = useState(answer.option_status);
    const [edit_modal, set_edit_modal] = useState(none);
    const [delete_modal, set_delete_modal] = useState(none);
    const [create_option_button_active, set_create_option_button_active] = useState(visible);
    const [create_option_button_effect, set_create_option_button_effect] = useState(hidden);


    const delete_option = ()=>{
        const delete_data = {
            cbt_pass_code : cbt_data_passcode,
            cbt_question_id : cbt_question_id,
            cbt_answer_id : answer.answer_id
        }

        axios.post(delete_single_answer, delete_data, ajaxconfig).then((response)=>{
            console.log(response);
            option_refresh();
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
    }

    const open_edit_modal = ()=>{
        set_edit_modal(block);

    }
    const close_edit_modal = ()=>{
        set_edit_modal(none);

    }


    const open_delete_modal = ()=>{
        set_delete_modal(block);
    }
    const close_delete_modal = ()=>{
        set_delete_modal(none);
    }


    const update_option = (e)=>{
        e.preventDefault();
        set_create_option_button_active(hidden);
        set_create_option_button_effect(visible);
        const data = {
            option : current_option,
            cbt_question_id: cbt_question_id,
            cbt_option_id: answer.answer_id,
            cbt_pass_code: cbt_data_passcode
        }

        axios.post(update_cbt_question_answer, data, ajaxconfig).then((response)=>{
            console.log(response);
            set_create_option_button_active(visible);
            set_create_option_button_effect(hidden);
            set_edit_modal(none);
            toast(response.data.message);
        }).catch((error)=>{
console.log(error);
set_create_option_button_active(visible);
set_create_option_button_effect(hidden);
            toast(error);
        });
    }
    return(<>
    
    <div className='card mt-3 w-100'  key={answer.id}>
        <div className='card-header'><h4 style={{color:'red'}} className='text-center'>{option_status}</h4></div>
        <div className='card-body' dangerouslySetInnerHTML={{__html:current_option}} />

       <div className='card-footer d-flex'>
       <button className="btn btn-info mr-2" onClick={open_edit_modal}>Edit</button>
       <button className="btn btn-danger mr-2" onClick={open_delete_modal}>Delete</button>
       </div>
    </div>
 
                            <div id="modal" className="modal"  style={edit_modal}>
    <div className="modal-content">
        <div className="card">
            <div className="card-header">
                <button className="btn btn-danger" onClick={close_edit_modal}>Close</button>
            </div>
            <div className="card-body">
            <form  >
                <h4 className="text-center">Edit option</h4>
                <div className="form-group">
                    <label>
                        <h6>Option</h6>
                        <textarea rows="10" value={current_option} onChange={(e)=>set_current_option(e.target.value)} />
                    </label>
                </div>
                <div className="form-group">
                    <label>
                        <h6>Option Status</h6>
                        <select value={option_status} onChange={(e)=>set_option_status(e.target.value)} >
                            <option>options</option>
                            <option>correct</option>
                        </select>
                    </label>
                </div>
                <div className="form-group mb-3" style={create_option_button_active}>
                        <button onClick={update_option} className="btn btn-danger w-100">Edit option</button>
                    </div>
                    <div className="form-group mt-1 mb-1" style={create_option_button_effect} >
                           <button className="btn btn-danger w-100" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                       </div>

            </form>    

            </div>
        </div>
    </div>
</div>

<div id="modal" className="modal"  style={delete_modal}>
    <div className="modal-content">
        <div className="card">
            <div className="card-body">
                <p className="text-justify">Do you want to delete {current_option}</p>
            </div>
            <div className="card-footer d-flex flex-wrap">
                <button className="btn btn-info" onClick={delete_option}>Delete</button>
                <button className="btn btn-danger" style={{marginLeft: '7px'}} onClick={close_delete_modal}>Cancel</button>
            </div>
        </div>
    </div>
</div>
    </>)
});


const CbtQuestionEdit = ()=>{




    const [score, set_score] = useState(1);
    const [score_setting, set_score_setting] = useState("false");
    const [cbt_data_validate, set_cbt_data_validate] = useState(false);
    const [question_type_id, set_question_type_id] = useState("objectives");
    const [sort, set_sort] = useState(1);

    // cbt config
    const [question_status, set_question_status] = useState("");
    const [multi_choice, set_multi_choice] = useState("");
    const [question_type, set_question_type] = useState("");
    const [display_sort, set_display_sort] = useState("true");
    const [score_style, set_score_style] = useState(hidden);
    const [sort_style, set_sort_style] = useState(hidden);

    const [answer, set_answer] = useState([]);

    // db question 
    const [db_img_files, set_db_img_file] = useState([]);
    const [db_questions, set_db_questions] = useState("");
    const [cbt_question_id, set_cbt_question_id] = useState("");
    const [diagramloop, set_diagram_loop] = useState([]);

    // create answer
    const [option, set_option] = useState("");
    const [option_status, set_option_status] = useState("options");
    const [custom_mark_status, set_custom_mark_status] = useState("false");
    const [custom_mark, set_custom_mark] = useState("");
    // get the url parameter
    const url_param = new URLSearchParams(window.location.search);
    const cbt_data_passcode = url_param.get("cbt_data_id"); 
    const cbt_question_passcode = url_param.get("cbt_question_id"); 

    const [question_config_modal, set_question_config_modal] = useState(none);
    const [img_modal, set_img_modal] = useState(none);
    const [update_question_button_active, set_update_question_button_active] = useState(visible);
    const [update_question_button_effect, set_update_question_button_effect] = useState(hidden);
    const [create_option_button_active, set_create_option_button_active] = useState(visible);
    const [create_option_button_effect, set_create_option_button_effect] = useState(hidden);

   

    // question images
    const OpenImgModal = ()=>{
        set_img_modal(block);
    }

    const CloseImgModal = ()=>{
        set_img_modal(none);
    }

    
    const OpenQuestionConfig = ()=>{
        set_question_config_modal(block);
    }

    const closeQuestionConfig = ()=>{
        set_question_config_modal(none);
    }
    
    // validate cbt data id


    useEffect(() =>{

        // validating cbt data id
        const url = CbtDataIdPrivateCbtDataVerify+cbt_data_passcode;
        axios.get(url, ajaxconfig).then((response)=>{
            console.log(response);
            const cbt_data_id = response.data.data.cbt_data_id;

        
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });

        // file server
        axios.get(private_all_asset, ajaxconfig).then((response)=>{
            // console.log(123);
            console.log(response);
            set_db_img_file(response.data.img_files);
        }).catch((error)=>{

            // console.log(456);
            console.log(error);
        });
        const diagram_question_url = diagram_question + cbt_question_passcode;

        axios.get(diagram_question_url, ajaxconfig).then((response)=>{
            set_diagram_loop(response.data.diagram);
            console.log(response);

        }).catch((error)=>{
            toast(error);
            console.log(error);
        });

        // fetching already created single question all answers
        const single_question_url = SinglePrivateQuestionGet+"/"+cbt_data_passcode+"/"+cbt_question_passcode;

        axios.get(single_question_url, ajaxconfig).then((response)=>{

            const single_question_db_fetch = response.data.cbt_question;
            
            console.log(response);
            console.log(response);
            if(!single_question_db_fetch){
                toast("an error occur");
            }
            if(single_question_db_fetch){
                set_db_questions(single_question_db_fetch);
                set_answer(response.data.cbt_answer);
                set_cbt_question_id(response.data.cbt_question_id);
    set_sort(1);
    set_question_status(response.data.cbt_question_status);
    set_multi_choice("false");
    set_question_type("Q & A");
    set_display_sort(response.data.display_sort);
    set_sort(response.data.sort);
            }
        }).catch((error)=>{
            toast(error);
            console.log(error);
        });
    }, []
);
   
    const refresh_question = ()=>{

                // fetching already created single question all answers
                const single_question_url = SinglePrivateQuestionGet+"/"+cbt_data_passcode+"/"+cbt_question_passcode;

                axios.get(single_question_url, ajaxconfig).then((response)=>{
        
                    const single_question_db_fetch = response.data.cbt_question;
                    
                    console.log(response);
                    console.log(response);
                    if(!single_question_db_fetch){
                        toast("an error occur");
                    }
                    if(single_question_db_fetch){
                        set_db_questions(single_question_db_fetch);
                        set_answer(response.data.cbt_answer);
                        set_cbt_question_id(response.data.cbt_question_id);
            set_sort(1);
            set_question_status(response.data.cbt_question_status);
            set_multi_choice("false");
            set_question_type("Q & A");
            set_display_sort(response.data.display_sort);
            set_sort(response.data.sort);
                    }
                }).catch((error)=>{
                    toast(error);
                    console.log(error);
                });
    }
 
    // submit question 
    const submit = (event)=>{
        set_update_question_button_active(hidden);
        set_update_question_button_effect(visible);
        event.preventDefault();
        const data = {
            cbt_question : db_questions,
            cbt_question_id: cbt_question_id,
            sort : sort,
            cbt_question_status : question_status,
            display_sort : display_sort,
            cbt_data_id : cbt_data_passcode,
            score : score,
            question_type_id: question_type_id

        }
        
        axios.post(update_cbt_question, data, ajaxconfig).then((response)=>{
            set_update_question_button_active(visible);
            set_update_question_button_effect(hidden);
            console.log(response);
            refresh_question();
            toast(response.data.message);
        }).catch((error)=>{
            set_update_question_button_active(visible);
            set_update_question_button_effect(hidden);
            toast(error);
            console.log(error);
        });
    }

    // creating new answers 
    const answersubmit = (event)=>{
        event.preventDefault();
        set_create_option_button_active(hidden);
        set_create_option_button_effect(visible);
        // data
        const submitanswer = {
            option : option,
            option_status: option_status, 
            custom_mark_status : custom_mark_status, 
            custom_mark : custom_mark,
            cbt_question_id: cbt_question_passcode,
            cbt_data_id : cbt_data_passcode
        }

        // console.log(submitanswer);
        axios.post(CreateCbtQuestionAnswer, submitanswer, ajaxconfig).then((response)=>{
            console.log(response);
            set_option("");
            option_refresh();
            set_create_option_button_active(visible);
            set_create_option_button_effect(hidden);
            toast(response.data.message);
        }).catch((error)=>{
            set_create_option_button_active(visible);
            set_create_option_button_effect(hidden);
            toast(error);
            console.log(error);
        });
    }

    const option_refresh = ()=>{
                // fetching already created single question all answers
                const single_question_url = SinglePrivateQuestionGet+"/"+cbt_data_passcode+"/"+cbt_question_passcode;

                axios.get(single_question_url, ajaxconfig).then((response)=>{
        
                    const single_question_db_fetch = response.data.cbt_question;
                    
                    console.log(response);
                    if(!single_question_db_fetch){
                        toast("an error occur");
                    }
                    if(single_question_db_fetch){
                        set_db_questions(single_question_db_fetch);
                        set_answer(response.data.cbt_answer);
                        set_cbt_question_id(response.data.cbt_question_id)
                    }
                }).catch((error)=>{
                    toast(error);
                    console.log(error);
                });
    }

    const diagram_refresh = ()=>{
    
            const diagram_question_url = diagram_question + cbt_question_passcode;

        axios.get(diagram_question_url, ajaxconfig).then((response)=>{
            set_diagram_loop(response.data.diagram);
            console.log(response);

        }).catch((error)=>{
            toast(error);
            console.log(error);
        });

    }
    return(
        <>

            <d-section className="main-container">
        <side className="side-close" id="side-menu">
           
           <SideMenu />
    
        </side>
       
            <main className="main-close" id="main" >
                 <div className="header-container" style={{width: '100%'}} >        
                    <TopMenu />
                </div>
    

<div className="card static" style={{marginTop: '5%'}}>
    <div className="card-body" style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', rowGap: '7px', columnGap: '7px'}}>
{diagramloop.map((data)=>(
    <DiagramLoop diagram={data} diagram_refresh={diagram_refresh} />
))}
    </div>
</div>


<div className="custom-card mt-3" >
<div className="card static w-100 card-size" >
          <div className="card-header d-flex">
            <button onClick={OpenQuestionConfig} className="btn btn-danger">Config</button>
            <button onClick={OpenImgModal} style={{marginLeft: '7px'}} className="btn btn-danger">Img</button>
          </div>

<form className="card-body w-100 mx-auto"  onSubmit={submit} >
    
  
          <div >
                  <div className="form-group-2 mt-2">
                  
                      <label>
                      <textarea placeholder="please type question..." rows="10" value={db_questions} onChange={(e)=>set_db_questions(e.target.value)} />
                      </label>
                  </div>
                  
              
                  <div className="form-group-2 mt-2" style={score_style}>
                  
                  <label>
                      <h6>custom score</h6>
                      <input type="number" value={score} onChange={(e)=>set_score(e.target.value)} />
                  </label>
                  </div>
  
  
                  <div className="form-group-2 mt-2" style={sort_style}>
                  
                  <label>
                      <h6>sort</h6>
                      <input type="number" value={sort} onChange={(e)=>set_sort(e.target.value)} />
                  </label>
                  </div>
  
                  <div className="form-group-2 mt-1 mb-2" style={update_question_button_active}>
                      <button className="btn btn-danger w-100" >Update Question </button>
                  </div>
                  <div className="form-group mt-1 mb-1" style={update_question_button_effect} >
                           <button className="btn btn-danger w-100" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                    </div>
          </div>          
      
  </form>
  </div>         
  </div>         



<div className="custom-card mt-2" >
<div className="card static mt-5 w-100 mx-auto">
        <div className="card-header">
            <h4 className="text-center"><b>Add Options</b></h4>
        </div>
        <div className="card-body">
                <form class="mx-auto w-100" onSubmit={answersubmit} >
                    
                   
                    <div className="form-group">
            
                        <label>
                            <h6>Option</h6>
                            <textarea placeholder="please type options" rows="10" value={option} onChange={(e)=>set_option(e.target.value)} />
                        </label>
                    </div>
                    <div className="form-group">
                        <label>
                            <h6>Option Status</h6>
                            <select value={option_status} onChange={(e)=>set_option_status(e.target.value)}>
                                <option>options</option>
                                <option>correct</option>
                            </select>
                        </label>
                    </div>
                    <div className="form-group mb-3" style={create_option_button_active}>
                        <button className="btn btn-danger w-100">Add option</button>
                    </div>
                    <div className="form-group mt-1 mb-1" style={create_option_button_effect} >
                           <button className="btn btn-danger w-100" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                       </div>
                </form>

        </div>

</div>
</div>


<div className="custom-card mt-2 mb-5" >
         
                        {
                            answer.map((answer)=>(
                                <OptionLoop option_refresh={option_refresh} answer={answer} cbt_data_passcode={cbt_data_passcode} cbt_question_id={cbt_question_id} />
                                                       ))
                        }
                
      
</div>

{/* imgs server modal */}
<div id="modal" className="modal"  style={img_modal}>
    <div className="modal-content">
        <div className="card">
            <div className="card-header">
                <button onClick={CloseImgModal} className="btn btn-danger">Close</button>
            </div>
            <div className="card-body">
{/* imgs */}
<div className="" style={{display: 'flex', justifyContent: 'space-around', flexWrap: 'wrap', rowGap: '7px', columnGap: '7px'}}>
                {
                    db_img_files.map((data)=>(
<QuestionImageLoop diagram_refresh={diagram_refresh} set_img_modal={set_img_modal} cbt_question_id = {cbt_question_id} data={data} />
                        ))
                }
            </div>

{/* imgs */}
            </div>
        </div>
    </div>
</div>

{/* imgs server modal end */}

{/* question config modal */}

<div id="modal" className="modal"  style={question_config_modal}>
    <div className="modal-content">

    <div className="card w-100 static mt-2 mb-3">
          <div className="card-header">
              <h6 className="text-center">Config</h6>
          </div>
          
          <div className="card-body">
                            
  
              <div className="form-group-2 mt-2" >
                                     <label>
                        <h6>display sort:</h6>
                        <select value={display_sort} onChange={(e)=>set_display_sort(e.target.value)} >
                            <option>true</option>
                            <option>false</option>
                        </select>
                    </label>
                
                  
              </div>
      
  
  
              <div className="form-group-2 mt-2" >
                  <b>sort:</b>
                 <div style={{display: 'flex'}}>
                  <label style={{display: 'flex'}}>
                          <b> No</b>
                          <input onClick={()=>set_sort_style(hidden)} type="radio" className="radio" name="custom_score" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>Yes</b>
                          <input  onClick={()=>set_sort_style(visible)} type="radio" className="radio" name="custom_score" /> 
                      </label>
                 </div>
  
              </div>
  
  
              <div className="form-group-2 mt-2" >
                  
                                     <label>
                        <h6>Question status:</h6>
                        <select value={question_status} onChange={(e)=>set_question_status(e.target.value)} >
                            <option>published</option>
                            <option>draft</option>
                        </select>
                    </label>
  
              </div>
              
          {/* laste */}
          </div>
          <div className="card-footer">
            <button className="btn btn-danger" onClick={closeQuestionConfig}>Close</button>
          </div>
  </div> 
{/* end */}
    </div>
</div>

{/* question config modal end */}
<ToastContainer />
          </main>
    </d-section>
        </>
        );
}



const CbtData = ()=>{

    

    const [create_cbt_modal, set_create_cbt_modal] = useState(none);
    const [question, setquestion] = useState("");

    const [score, set_score] = useState(1);
    const [score_setting, set_score_setting] = useState("false");
    const [cbt_data_validate, set_cbt_data_validate] = useState(false);
    const [question_type_id, set_question_type_id] = useState("objectives");
    const [sort, set_sort] = useState(1);

    // db question 
    const [db_questions, set_db_questions] = useState([]);
    // get the url parameter
    const url_param = new URLSearchParams(window.location.search);
    const cbt_data_passcode = url_param.get("cbt_data_id"); 

    // cbt config
    const [question_status, set_question_status] = useState("draft");
    const [multi_choice, set_multi_choice] = useState("false");
    const [question_type, set_question_type] = useState("Q & A");
    const [display_sort, set_display_sort] = useState("true");
    const [score_style, set_score_style] = useState(hidden);
    const [sort_style, set_sort_style] = useState(hidden);
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);
    const [cbt_data, set_cbt_data] = useState("");

    const mass_question_url = MassPrivateQuestionGet+cbt_data_passcode;

    
    // validate cbt data id

    useEffect(() =>{

        // validating cbt data id
        const url = CbtDataIdPrivateCbtDataVerify+cbt_data_passcode;
        axios.get(url, ajaxconfig).then((response)=>{
            console.log(response);
            const cbt_data_id = response.data.data.cbt_data_id;

            if(cbt_data_id){
                set_cbt_data(response.data.data);
            }

        
        }).catch((error)=>{

            console.log(error);
        });


        // fetching already created questions 

        axios.get(mass_question_url, ajaxconfig).then((response)=>{

            const mass_question_db_fetch = response.data.data;
            if(!response.data.data){

            }
            if(mass_question_db_fetch){
                set_db_questions(mass_question_db_fetch);
            }
        }).catch((error)=>{

        });
    }, []
);
   

const refresh_question = ()=>{
    axios.get(mass_question_url, ajaxconfig).then((response)=>{

        const mass_question_db_fetch = response.data.data;
        if(!response.data.data){

        }
        if(mass_question_db_fetch){
            set_db_questions(mass_question_db_fetch);
            toast(response.data.message);
        }
    }).catch((error)=>{

    });
}
const closeModal = ()=>{
    set_create_cbt_modal(none);   
}
const open_create_cbt_modal = ()=>{
    //
    set_create_cbt_modal(block);
}
 
    // submit question 
    const submit = (event)=>{

        event.preventDefault();
        set_button_active(hidden);
        set_button_effect(visible);
        const data = {
            question : question,
            cbt_data_id : cbt_data_passcode,
            score : score,
            score_setting: score_setting,
            question_type_id: question_type_id,
            question_type : question_type,
            sort: sort,
            display_sort : display_sort, 
            cbt_question_status : question_status

        }
        axios.post(CbtQuestionCreate, data, ajaxconfig).then((response)=>{
            console.log(response);
            set_button_active(visible);
            set_button_effect(hidden);
            set_create_cbt_modal(none);   
            refresh_question();

        }).catch((error)=>{
            toast(error);
            set_button_active(visible);
            set_button_effect(hidden);
            console.log(error);
        });
    }
  
    return(
        <>
            <d-section className="main-container">
        <side className="side-close" id="side-menu">
           
           <SideMenu />
    
        </side>
       
            <main className="main-close" id="main" >
                <div className="header-container" style={{width: '100%'}} >
                    <TopMenu />
                </div>
    
                <ToastContainer />



<div className="custom-card mt-5" >
<div className="card w-100 mt-3 mx-auto static">
        <div className="card-header">
            <h6 className='text-center'>CBT Data</h6>
        </div>
        <div className="card-body">
<Table aria-label="striped table">
    <thead>
    <tr>
        <td>CBT</td><td>{cbt_data.cbt_name}</td>
    </tr>
    <tr>
        <td>CBT PassCode</td><td>{cbt_data.cbt_data_id}</td>
    </tr>
    <tr>
        <td>CBT Status</td><td>{cbt_data.cbt_status}</td>
    </tr>
    <tr>
        <td>Grade point</td><td>{cbt_data.default_score}</td>
    </tr>
    <tr>
        <td>Result sheet</td><td><a href={'/cbt_creator/test_result/'+cbt_data.cbt_data_id} rel="noopener noreferrer"  target="__blank">view</a></td>
    </tr>
    </thead>
</Table>
        </div>
</div>
</div>

<div className="custom-card mt-5" >
<div className="card w-100 mt-3 mx-auto static">
                    <div className="card-header mt-2 d-flex flex-wrap">
                    <button className="btn btn-info" style={{width: '100px'}} onClick={open_create_cbt_modal}>Create</button>
                        <h6 style={{marginLeft: '8px'}}>Created Question Numbers</h6>
                    </div>

            {/* card body */}
            <div className="card-body mt-2">

                        <div className="d-flex flex-wrap mx-auto">
                    {
                        db_questions.map((question)=>(
                            <a className="mt-2 mb-2" style={{marginLeft: '8px'}} href={"/cbt_creator/cbt_question_edit?cbt_data_id="+cbt_data_passcode + "&cbt_question_id=" + question.cbt_question_id} ><button className="btn btn-danger">{question.sort}</button></a>
                        ))
                    }
                </div>


                    
            </div>
            {/* card body */}

</div>
</div>


<div className="custom-card mt-2" >

                        {
                            db_questions.map((question)=>(
                                <QuestionLoop cbt_pass_code={cbt_data_passcode} refresh_question={refresh_question} question={question} />
                            ))
                        }
              
</div>
          </main>


          {/* modals */}



          <div id="modal" className="modal"  style={create_cbt_modal}>
    <div className="modal-content">
{/*  */}

<form  className=""  onSubmit={submit} >
  
    
  <div className="card w-100 static mt-2 mb-3">
          <div className="card-header">
              <h6 className="text-center">Config</h6>
          </div>
          
          <div className="card-body">
              
                          
              <div className="form-group-2 mt-2" >
                                     <label>
                        <h6>display sort:</h6>
                        <select value={display_sort} onChange={(e)=>set_display_sort(e.target.value)} >
                            <option>true</option>
                            <option>false</option>
                        </select>
                    </label>
                
                  
              </div>
      
  
  
              <div className="form-group-2 mt-2" >
                  <b>sort:</b>
                 <div style={{display: 'flex'}}>
                  <label style={{display: 'flex'}}>
                          <b> No</b>
                          <input onClick={()=>set_sort_style(hidden)} type="radio" className="radio" name="custom_score" /> 
                      </label>
                      <label style={{display: 'flex'}}>
                          <b>Yes</b>
                          <input  onClick={()=>set_sort_style(visible)} type="radio" className="radio" name="custom_score" /> 
                      </label>
                 </div>
  
              </div>
  
  
              <div className="form-group-2 mt-2" >
                  
                                     <label>
                        <h6>Question status:</h6>
                        <select value={question_status} onChange={(e)=>set_question_status(e.target.value)} >
                            <option>published</option>
                            <option>draft</option>
                        </select>
                    </label>
  
              </div>
              
  

              
          {/* laste */}
          </div>
  </div> 
  
  
  
      <div className="card static w-100" >
  
          <div className="card-body">
                  <div className="form-group-2 mt-2">
                  
                      <label>
                      <textarea placeholder="please type question..." rows="10" value={question} onChange={(e)=>setquestion(e.target.value)} />
                      </label>
                  </div>
                  
              
                  <div className="form-group-2 mt-2" style={score_style}>
                  
                  <label>
                      <h6>custom score</h6>
                      <input type="number" value={score} onChange={(e)=>set_score(e.target.value)} />
                  </label>
                  </div>
  
  
                  <div className="form-group-2 mt-2" style={sort_style}>
                  
                  <label>
                      <h6>sort</h6>
                      <input type="number" value={sort} onChange={(e)=>set_sort(e.target.value)} />
                  </label>
                  </div>
  
                  <div className="form-group-2 mt-1 mb-2">
                      <button className="btn btn-info w-100" style={button_active}>Create Question </button>
                  </div>
                  <div className="form-group mt-1 mb-1" style={button_effect} >
                           <button className="btn btn-danger w-100" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                  </div>

          </div>          
      </div>         
      

  </form>
  <div className="form-group-2 mt-1 mb-2 d-flex">
                      <button onClick={closeModal} className="btn btn-danger flex-end" style={{width: '100px'}}>Cancel</button>
                  </div>
{/*  */}
    </div>
</div>

    </d-section>
        </>
        );
    
}

const QuestionLoop = forwardRef(({question, cbt_pass_code, refresh_question})=>{
    const [delete_modal, set_delete_modal] = useState(none);
    const [button_active, set_button_active] = useState(visible);
    const [button_effect, set_button_effect] = useState(hidden);

    const DeleteQuestion = ()=>{

        const data = {
            cbt_question_id : question.cbt_question_id
        }
        set_button_active(hidden);
        set_button_effect(visible);
        axios.post(delete_cbt_question, data, ajaxconfig).then((response)=>{
           console.log(response);
            toast(response.data.message);
            set_delete_modal(none);
            refresh_question();
        }).catch((error)=>{
            console.log(error);
            set_button_active(visible);
            set_button_effect(hidden);
            toast(error);
        })
    }
    const DeleteModal = ()=>{
        // alert(123);
        set_delete_modal(block);
    }
    
    const CloseDeleteModal = ()=>{
        set_delete_modal(none);
    }
    

    return(<>
    <div key={question.id} className='card mt-3 static'>
        <div className='card-header'>
            <h5 className='text-center'>{'Question '+question.sort}</h5>
        </div>
        <div dangerouslySetInnerHTML={{__html:question.cbt_question}} className='card-body' />
        <div className='card-footer d-flex'>
        <a href={"/cbt_creator/cbt_question_edit?cbt_data_id="+cbt_pass_code + "&cbt_question_id=" + question.cbt_question_id} ><button className="btn btn-danger">Edit Question</button>
        </a>
        <button onClick={DeleteModal} style={{marginLeft: '7px'}} className="btn btn-danger mr-2">Delete</button>
        </div>

    </div>


                            {/* delete modal */}

<div id="modal" className="modal"  style={delete_modal}>
    <div className="modal-content">
        <div className="card">
            <div className="card-body">
                <p style={{color: 'red'}}>Do you want to delete : </p>
                <p className="text-justify">{question.cbt_question}</p>
            </div>
            <div className="card-footer d-flex flex-wrap">
                <button style={button_active} className="btn btn-danger" onClick={DeleteQuestion}>Confirm</button>
                <button style={button_effect} className="btn btn-danger w-25" disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
                <button className="btn btn-info"  style={{marginLeft: '5px'}} onClick={CloseDeleteModal}>Cancel</button>
            </div>
        </div>
    </div>
</div>
{/* end delete modal */}

    </>)
});

export  {Cbt,  CbtQuestionEdit, CbtAnswerEdit, CbtData};