import React from "react";
import TopMenu from "./components/top_menu";
import MobileTopMenu from "./components/mobile_top_menu";
import { FooterMenu } from "./components/footer_menu";
import SideMenu from "./components/side_menu";
import DashboardWidget from "./components/dashboard_widget";
import { useNavigate } from "react-router-dom";

function Admindashboard(){

    const navigate = useNavigate();
    const creator = ()=>{
        navigate('/cbt_creator/create_cbt');
    }

    const user = ()=>{
        navigate('/cbt_user/cbt_pass_code');
    }
    return(
        <>
        <d-section className="main-container">
    <side className="side-close" id="side-menu">
       
       <SideMenu />

    </side>
    <main className="main-close" id="main" >
        <div className="header-container" style={{width: '100%'}} >
            <TopMenu />
        </div>
        <div className="" style={{marginTop: '70px'}}>
            <div className="card mt-2 mx-auto static card-size mt-2" style={{padding: '5%'}}>
                    <h1 className="mt-1 text-center">
                    <img src="/asset/singlelogo.png" className="img_homepage" alt="cbtdraft logo" />
                    </h1>
                    <button onClick={creator} className="btn btn-info mt-2"><b>CREATE CBT</b></button>
                    <button onClick={user} className="btn btn-danger mt-2"><b>CBT</b></button>
            </div>
        </div>
        


      </main>
</d-section>    
        </>
    )
}

export default Admindashboard;