import React from 'react';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {PrivateRoute} from './auth';
import './index.css';
import './home_page.css';
import Homepage from './cbt_lms/homepage';
import {Url}  from './cbt_lms/url';
import Login from './cbt_lms/login';
import Signup from './cbt_lms/sign_up';
import {StaticExample} from './cbt_lms/admin/components/modaltest';

// blogposts
import Blog_view from './cbt_lms/blog_view';


// admin, unit, staff, course  portal 
import Admindashboard from './cbt_lms/admin/dashboard';
// import TestResult from './cbt_lms/admin/test_result';
import { TestResult } from './cbt_lms/admin/test_result';
import {Cbt, CbtQuestionEdit, CbtData, CbtAnswerEdit} from "./cbt_lms/admin/p_cbt";
import CbtSession from './cbt_lms/admin/cbt_session';
import { CbtImgLibrary } from './cbt_lms/admin/img_library';

import {CbtPassCode, CbtPassCodeVerification, CbtQuestion} from "./cbt_lms/student/cbt";


// primary pages 
import { ContactUs } from './cbt_lms/contact_us';
import { AboutUs } from './cbt_lms/about_us';
import { Tos } from './cbt_lms/tos';
import { Privacy } from './cbt_lms/privacy';
import { Notfound } from './cbt_lms/404';
import PasswordReset from './cbt_lms/password_reset';
import Profile from './cbt_lms/profile';
import SignUp_ from './cbt_lms/sign_up_';
import { Helmet, HelmetProvider } from 'react-helmet-async';



// blog

import {CbtBlog} from "./cbt_lms/cbt";
import Category from './category';
function App(){

  return(
    <HelmetProvider>
    <BrowserRouter>

    <Routes>

        {/* pages start */}
        <Route path="blog/post/:post_id" element={<Blog_view />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="/" element={<Url/>} />
        <Route index element={<Homepage />} />
        <Route path="login" element={<Login />} />
        <Route path="sign-up" element={<SignUp_ />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="tos" element={<Tos />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="password-reset" element={<PasswordReset />} />
        <Route path="*" element={<Notfound />} />
       
        {/*pages end */}



        {/* admin, unit, staff, student urls */}
        <Route path="cbt_creator" element={<PrivateRoute />} >
          <Route path="dashboard" element={<Admindashboard />} />
 
          <Route path="cbt_img_library" element={<CbtImgLibrary />} />
 
          <Route path="cbt_session" element={<CbtSession />} />
          <Route path="cbt_data" element={<CbtData />} />
          <Route path="create_cbt" element={<Cbt />} />
          <Route path="cbt_question_edit" element={<CbtQuestionEdit />} />
          <Route path="cbt_question_edit_answer" element={<CbtAnswerEdit />} />
          <Route path="profile" element={<Profile />} />
          <Route path="test_result/:cbt_pss_code" element={<TestResult />} />
        </Route>
    {/* student cbt urls */}

      <Route path="cbt_user" element={<PrivateRoute />} >
     
            <Route path="cbt_pass_code" element={<CbtPassCode />} />
            <Route path="verification" element={<CbtPassCodeVerification />} />
            <Route path="cbt_question" element={<CbtQuestion />} />
      </Route>


      </Routes>

  </BrowserRouter>
    </HelmetProvider>

  )
}


export default App;