import React from 'react';
import { Button } from '@mui/joy';
import {toast } from 'react-toastify';
import { ajaxconfig } from "../../ajaxconfig";
import axios from 'axios';
import {useState,  useEffect,forwardRef, useRef, useImperativeHandle} from 'react';
import {UpdateCbtDataId,  Deletecbtdata} from "../../serverurl";
const block = "block";
const none = "none";


const hidden = {
    display: 'none'
};
const visible = {
    visibility: 'block'
};

const Cbtdata_list = forwardRef(({datalist, refreshpage})=>{

  const [delete_modal, set_delete_modal] = useState(none);
  const [button_active, set_button_active] = useState(visible);
  const [button_effect, set_button_effect] = useState(hidden);

  const close_modal = ()=>{
    set_delete_modal(none);
   }

   const open_delete_modal = ()=>{
    set_delete_modal(block);
   }
   


        const [edit_data_modal, set_edit_data_modal] = useState(none);


        const [delete_cbt_data, set_delete_cbt_data] = useState("");
        const [edit_cbt_data, set_edit_cbt_data] = useState("");
        const [delete_cbt_data_info, set_delete_cbt_data_info] = useState("");

        // edit 
        const [cbt_name_f, set_cbt_name ] = useState(datalist.cbt_name);
        const [attempt_limit, set_attempt_limit] = useState(datalist.attempt_limit);
        const [default_score, set_default_score] = useState(datalist.default_score);
        const [cbt_duration, set_cbt_duration] = useState(datalist.cbt_duration);
        const [max_question, set_max_question] = useState("");
        const [random_question, set_random_question] = useState("no");
        // const [cbtdata, setcbtdata] = useState([]);
        const [duration_style, set_duration_style] = useState(hidden);
        const [max_attempt_style, set_max_attempt_style] = useState(hidden);
        const [score_style, set_score_style] = useState(hidden);
        const [cbt_status, set_cbt_status] = useState(datalist.cbt_status);
    
        // edit end

    // console.log(datalist);
      const deleteClose = () => {delete_modal(none);  }
    
        const delete_data = ()=>{

            set_button_active(hidden);
            set_button_effect(visible);
    
            set_delete_cbt_data(datalist.cbt_data_id);
             set_delete_cbt_data_info(datalist.cbt_name);
            //  alert(datalist.cbt_data_id);
             const data = {
                cbt_pssc : datalist.cbt_data_id
            }
            axios.post(Deletecbtdata, data, ajaxconfig).then((response)=>{
    
                console.log(response);
                set_button_active(visible);
                set_button_effect(hidden);
    
                set_delete_modal(none);
                
                refreshpage();
            }).catch((error)=>{
                set_button_active(visible);
                set_button_effect(hidden);
    
                toast(error);
                set_delete_modal(none);
                console.log(error);
            });

            // setShow(true)
    
        }
    
        const save_cbt_data = ()=>{

            // e.preventDefault();
            const data = {
                'cbt_name' : cbt_name_f,
                'attempt_limit' : attempt_limit,
                'default_score' : default_score,
                'cbt_duration'  : cbt_duration,
                'max_question'  : max_question,
                'random_question_mode' : random_question,   
                'cbt_status' : cbt_status,
                'cbt_pass_code' : datalist.cbt_data_id,
            }
            // console.log("hello world");
                        const updateurl = UpdateCbtDataId;
            axios.post(updateurl, data, ajaxconfig).then((response)=>{
                console.log(response);
                refreshpage();
                set_edit_data_modal(false)
                toast(response.data.message);

            }).catch((error)=>{
    
                toast(error);
                set_edit_data_modal(false)
                console.log(error);
            });

        }
        const editdata = ()=>{
            // alert("12345");
                            set_edit_data_modal(block);
                            set_edit_cbt_data(datalist.cbt_data_id);
                            set_cbt_name(datalist.cbt_name);
                            set_duration_style(visible);
                            set_max_attempt_style(visible);
                            set_score_style(visible);
                            set_cbt_status(datalist.cbt_status);




        }
    
        const closeEditdata = ()=>{
            set_edit_data_modal(none);   
        }

        return(<>
        
        <tr key={datalist.id}>   
                        <td><a style={{color:'blue'}} href={"/cbt_creator/cbt_data?cbt_data_id="+datalist.cbt_data_id} >{datalist.cbt_name}</a></td>
                        <td>{datalist.created_at}</td>
                        <td> <Button variant="outlined" style={{marginRight: '8px'}} onClick={editdata} color="neutral" >
        Edit
      </Button><button onClick={open_delete_modal} className="btn btn-danger" style={{marginLeft: '8px'}}>Delete</button></td>
                    </tr>  


{/* // delete new modal  */}
<div id="modal" className="modal"  style={{display: delete_modal}}>
    <div className="modal-content">

        <div className="card">
            <div className="card-body">
                <p className="text-justify">Proceed to delete  {datalist.cbt_data_id + ": " + datalist.cbt_name}</p>
            </div>
            <div className="card-footer d-flex flex-wrap justify-content-space-around">
            <button className="btn btn-info" onClick={delete_data} style={button_active}>Proceed</button>
            <button className="btn btn-danger" style={button_effect} disabled><span className="spinner-border spinner-border-sm text-primary" /></button>
            <button className="btn btn-danger" onClick={close_modal} style={{marginLeft: '8px'}}>Cancel</button>

            </div>
        </div>
    </div>
</div>


{/* // new modal end */}



{/* // edit new modal  */}
<div id="modal" className="modal"  style={{display: edit_data_modal}}>
    <div className="modal-content">

        {/*  */}
        <div className="card static" >




<div className="card w-75 static mt-2 mb-3 mx-auto">
        <div className="card-header">
            <h6 className="text-center">Config</h6>
        </div>
        
        <div className="card-body">
            

                <div className="form-group">
                    <label>
                        <h6>Cbt Status</h6>
                        <select value={cbt_status} onChange={(e)=>set_cbt_status(e.target.value)} >
                            <option>Published</option>
                            <option>Draft</option>
                        </select>
                    </label>
                </div>
            
        {/* laste */}
        </div>
</div> 




<div className="card static w-75 mx-auto" style={{padding: '10px'}}>

                <div className="form-group-2 mt-2">

                    <label>
                    <h6>CBT Name</h6>
                        <input 
                    value={cbt_name_f} onChange={(e)=>set_cbt_name(e.target.value)} placeholder="CBT name" 
                    /> </label>
                </div>

                

            <div className="form-group-2 mt-2" style={max_attempt_style} >

                <label>
                    <h6>Max Attempt</h6>
                    <input input type="number"
                value={attempt_limit} onChange={(e)=>set_attempt_limit(e.target.value)} placeholder="Attempt limit" 
                /> </label>
            </div>

            <div className="form-group-2 mt-2" style={score_style}>

                <label>
                    <h6>Default score</h6>
                    <input input type="number"
                value={default_score} onChange={(e)=>set_default_score(e.target.value)} placeholder="Default score" 
                /> </label>
            </div>
            <div className="form-group-2 mt-2" style={duration_style} >
                <label>
                <h6>Duration in minutes:</h6>
                    <input type="number"
                value={cbt_duration} onChange={(e)=>set_cbt_duration(e.target.value)} placeholder="CBT duration" 
                /> </label>
            </div>

                <div className="form-group-2 mt-1 mb-2 d-flex justify-space-between">
                    <button className="btn btn-success" style={{width: '100px'}} onClick={save_cbt_data}>Save </button>
                    <button className="btn btn-danger" style={{width: '100px', marginLeft: '8px'}} onClick={closeEditdata}>Cancel</button>
                </div>


        
</div>

</div>   


</div>
{/*  */}


</div>


{/* // new modal end */}





    
    



        </>)
    
});

export {
    Cbtdata_list 
}