import { useParams } from "react-router-dom";
import { Footer } from "../footer";
import { Header } from "../header";
import { test_result, ServerBased, Cbt_Csv } from "../serverurl";
import { useEffect, useState } from "react";
import axios from "axios";
import { ajaxconfig } from "../ajaxconfig";
import { toast, ToastContainer } from "react-toastify";
import { Table, Button } from "@mui/joy";

const TestResult = ()=>{


    const [result, set_result] = useState([]);
    const {cbt_pss_code} = useParams();

    useEffect(()=>{
        const url = test_result + cbt_pss_code;
        axios.get(url, ajaxconfig).then((response)=>{
            set_result(response.data.data);
            toast(response.data.message);
            console.log(response);
        }).catch((error)=>{

            console.log(error);
        });
    }, []);

    const fetch_result =()=>{

        axios.get(Cbt_Csv+cbt_pss_code, ajaxconfig).then((response)=>{

            console.log("processing result");
            window.open(ServerBased+"csv_files/"+cbt_pss_code+".csv", "__blank");
        }).catch((error)=>{

            console.log(error);
        });
    }


    return(<>
    <Header />
    <div className="container default-margin-top">
{/*  */}
    <div className="card mt-5 mx-auto static">

           <div className="card-header d-flex static" style={{justifyContent: "center", justifyItems:"center", alignItems: "center", gap:"10px"}}>
                <h4 className="text-center">Result sheet</h4>
              <Button variant="outlined" color="neutral" onClick={fetch_result}>
       Download Csv
      </Button>
           </div>
            <div className="card-body">
            <Table aria-label="striped table"  >
                <thead>
                <tr>
                    <th>#</th>
                    <th>Test id</th>
                    <th>PassCode</th>
                    <th>Score</th>
                    <th>Date</th>
                </tr>

                </thead>
          
                <tbody>
                {
                    result.map((data)=>(
                        <tr>
                            <td>#</td>
                            <td>{data.cbt_test_id}</td>
                            <td>{data.cbt_pass_code}</td>
                            <td>{data.cbt_total_score}</td>
                            <td>{data.created_at}</td>
                        </tr>
                    ))
                }

                </tbody>
            </Table>
            </div>

    </div>
{/*  */}
    </div>
    <div className="footer card">
<Footer />
<ToastContainer />
</div>
    </>)
}


export {
    TestResult
}